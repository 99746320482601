"use client";
import Link, { LinkTargetProps } from "@/atoms/Link";
import ButtonCalendly from "@/molecules/ButtonCalendly";
import { LogoSvg } from "@/components/svg/LogoSvg";
import { useIsClient } from "@/hooks/useIsClient";
import { cn } from "@/lib/utils";
import { motion, useMotionValue, useScroll, useTransform } from "framer-motion";
import { Phone } from "lucide-react";
import { useEffect, useState } from "react";

const clamp = (number: number, min: number, max: number) =>
  Math.min(Math.max(number, min), max);

function useBoundedScroll(threshold: number) {
  const { scrollY } = useScroll();
  const scrollYBounded = useMotionValue(0);
  const scrollYBoundedProgress = useTransform(
    scrollYBounded,
    [0, threshold],
    [0, 1],
  );

  useEffect(() => {
    let prevScrollY = window.scrollY;

    const onChange = (current: number) => {
      const diff = current - prevScrollY;
      const newScrollYBounded = scrollYBounded.get() + diff;

      // Only update if scrolling down or if we're at the top of the page
      if (diff > 0 || current === 0) {
        scrollYBounded.set(clamp(newScrollYBounded, 0, threshold));
      }

      prevScrollY = current;
    };

    const unsubscribeScrollY = scrollY.on("change", onChange);

    return () => {
      unsubscribeScrollY();
    };
  }, [threshold, scrollY, scrollYBounded]);

  return { scrollYBounded, scrollYBoundedProgress };
}

export function LandingHeader({ light = false }: { light?: boolean }) {
  const isClient = useIsClient();
  const { scrollYBoundedProgress } = useBoundedScroll(
    isClient ? window.innerHeight : 910,
  );
  const [headerOpen, setHeaderOpen] = useState(false);

  const $scroll = useTransform(scrollYBoundedProgress, (value) => {
    return Math.ceil(value * 100) / 100;
  });

  useEffect(() => {
    const unsubscriber = $scroll.on("change", (v) => {
      const newValue = (Math.ceil(v * 100) / 100) * 100;
      setHeaderOpen(newValue === 100);
    });
    return unsubscriber;
  }, [$scroll]);

  if (!isClient) return null;

  return (
    <motion.header
      className={cn(
        "fixed z-50 flex h-[60px] self-center origin-center place-self-center mx-auto shadow-md backdrop-blur-sm transition-all ease-in-out delay-200 duration-300",
        {
          "bg-background/40": !headerOpen,
          "bg-background/80": headerOpen,
          "md:max-w-7xl mx-auto md:rounded-full md:scale-95 max-md:top-0 md:top-2 w-full shadow-2xl border-[0.5px] border-foreground/50 opacity-100":
            headerOpen,
          "top-0 left-0 w-screen max-md:-top-[50px]  opacity-0": !headerOpen,
        },
      )}
    >
      <div className="mx-auto flex w-full  items-center justify-between px-4 lg:px-8">
        <div
          className={cn("size-[40px] rounded-lg", {
            "bg-foreground": !light,
            "bg-background": light,
          })}
          // className="bg-current/90 fixed left-0 top-0 flex size-[77px] rounded-br-[100%_50%] p-2 shadow-2xl"
        >
          <Link
            aria-label="Accueil"
            className="flex size-full items-center justify-center text-center"
            href="/"
          >
            <LogoSvg
              fill={`hsl(var(--${!light ? "background" : "foreground"}))`}
              size={32}
            />
          </Link>
        </div>
        <div>
          <ButtonCalendly
            all
            id="landing-header-cta"
            about="calendly"
            title={"Parlons de votre projet"}
            arrow={false}
            variant={"invert"}
          >
            {"Parlons de votre projet"}
          </ButtonCalendly>
        </div>
        <div
          className={cn("size-[40px] rounded-lg", {
            "bg-foreground": !light,
            "bg-background": light,
          })} // className="bg-current/90 fixed right-0 top-0 flex size-[77px] rounded-bl-[100%_50%] p-2 shadow-2xl"
        >
          <Link
            aria-label="Téléphonez-nous"
            className="flex size-full items-center justify-center text-center"
            href={"tel:+33780978892"} //
            target={LinkTargetProps.BLANK}
          >
            <Phone
              color={`hsl(var(--${!light ? "background" : "foreground"}))`}
            />
          </Link>
          {/* <Button
            size={"sm"}
            variant={"outline"}
            className="absolute right-2 top-2 flex size-[32px] flex-col justify-center"
          >
            <span className="-mt-3 max-h-2 font-extrabold">{"-"}</span>
          </Button> */}
        </div>
      </div>
    </motion.header>
  );
}

export default LandingHeader;
