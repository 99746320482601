"use client";
import { type PropsWithChildren } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import React from "react";

export type ScaleInertiaContainerProps = PropsWithChildren<{
  as?: "div" | "p" | "h1" | "h2" | "section" | "span" | "button";
  delay?: number;
  className?: string;
  once?: boolean;
}>;

export default function ScaleInertiaContainer({
  children,
  delay = 0,
  className,
  once,
  as = "div",
  ...props
}: ScaleInertiaContainerProps) {
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref, { once, amount: "some" });

  useEffect(() => {
    if (inView) {
      const timeOut: NodeJS.Timeout = setTimeout(() => {
        controls.start("visible");
      }, delay);

      return () => clearTimeout(timeOut);
    } else {
      controls.start("hidden");
    }
  }, [controls, inView, delay]);

  const variants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.3,
        ease: [0, 0.71, 0.2, 1.01],
        scale: {
          type: "spring",
          damping: 5,
          stiffness: 100,
          restDelta: 0.001,
        },
      },
    },
  };

  const motionProps = {
    ...props,
    children,
    ref,
    initial: "hidden",
    animate: controls,
    variants,
    className,
  };

  switch (as) {
    case "div":
      return <motion.div {...motionProps} />;
    case "p":
      return <motion.p {...motionProps} />;
    case "h1":
      return <motion.h1 {...motionProps} />;
    case "h2":
      return <motion.h2 {...motionProps} />;
    case "section":
      return <motion.section {...motionProps} />;
    case "span":
      return <motion.span {...motionProps} />;
    case "button":
      return <motion.button {...motionProps} />;
  }
}
