"use client";
import { type PropsWithChildren } from "react";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import React from "react";
import { cn } from "@/lib/utils";

export type BlurContainerProps = PropsWithChildren<{
  as?: "div" | "p" | "h1" | "h2" | "section" | "span" | "button";
  delay?: number;
  className?: string;
  duration?: number;
}>;

export default function BlurContainer({
  delay = 333,
  duration = 777,
  as = "div",
  ...props
}: BlurContainerProps) {
  const ref = useRef(null);
  const inView = useInView(ref);

  const motionProps = {
    ...props,
    className: cn(props.className, {
      "blur-md opacity-30": !inView,
      "blur-none opacity-100": inView,
    }),
    style: {
      transition: `all ${duration}ms cubic-bezier(0.17, 0.85, 0.85, 1) ${delay}ms`,
    },
  };

  switch (as) {
    case "div":
      return <motion.div ref={ref} {...motionProps} />;
    case "p":
      return <motion.p ref={ref} {...motionProps} />;
    case "h1":
      return <motion.h1 ref={ref} {...motionProps} />;
    case "h2":
      return <motion.h2 ref={ref} {...motionProps} />;
    case "section":
      return <motion.section ref={ref} {...motionProps} />;
    case "span":
      return <motion.span ref={ref} {...motionProps} />;
    case "button":
      return <motion.button ref={ref} {...motionProps} />;
  }
}
